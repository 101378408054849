.house {
  background-image: url(./img/fon.webp);
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 4.5vw;
  padding-bottom: 2vw;
}
.house h3 {
  color: #fff;
  text-align: center;
  font-family: Montserrat;
  font-size: 5vw;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 105.6px */
  text-transform: uppercase;
  text-align: center;
}
.house > p {
  color: #fff;
  background: #6fb075;
  font-family: Montserrat;
  font-size: 2vw;
  padding: 0.5vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 1.5vw;
}
.houseImg {
  margin-top: 10vw;
  width: 90vw;
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
}
.houseImg img {
  width: 25.52vw;
}
.houseAdd {
  margin-top: 18vw;
}
.ekz {
  border: 2px solid #fff;
  background: linear-gradient(
    210deg,
    rgba(73, 71, 120, 0.85) 7.13%,
    rgba(73, 71, 120, 0.54) 100.36%
  );

  backdrop-filter: blur(25px);
  padding: 1.5vw;
}
.ekz p {
  color: #fff;
  font-family: Montserrat;
  font-size: 1.6vw;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 38.4px */
  text-transform: uppercase;
}
.ekz {
  display: flex;
  align-items: center;
  padding-left: 3vw;
  margin-top: 4.3vw;
}
.ekz span {
  color: #fff;
  font-family: Montserrat;
  font-size: 1vw;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 24px */
  margin-top: 0.5vw;
  display: block;
}
.ekz .button {
  color: #fff;
  font-family: Montserrat;
  font-size: 1.1vw;
  margin-left: 8vw;
  padding: 1.7vw 6.5vw;
  border-radius: 1000px;
  background: #ba9766;
  border-radius: 6vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
  text-transform: uppercase;
}
@media (max-width: 640px) {
  .house {
    background-image: url(./img/mob.webp);
    padding-top: 7vw;
    padding-bottom: 8vw;
  }
  .house > p {
    color: #fff;
    background: #6fb075;
    font-family: Montserrat;
    font-size: 4vw;
    padding: 1.5vw;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 2.5vw;
  }
  .ekz p {
    color: #fff;
    font-family: Montserrat;
    font-size: 3.6vw;
    font-style: normal;
    text-align: center;
    font-weight: 600;
    line-height: 120%;
    text-transform: uppercase;
  }
  .house .carousel-dots li button:before {
    background: #fff !important;
    opacity: 1 !important;
  }
  .house .carousel-dots li.carousel-dots-active button:before {
    opacity: 1 !important;
    background: #dbb176 !important;
  }
  .ekz {
    width: 94vw;
    border: 2px solid #fff;
    background: linear-gradient(
      210deg,
      rgba(82, 19, 19, 0.7) -0.98%,
      rgba(84, 40, 30, 0.7) -0.97%,
      rgba(92, 37, 19, 0.35) 100.36%
    );
    -webkit-backdrop-filter: blur(25px);
    backdrop-filter: blur(25px);
    padding: 3.5vw;
  }
  .ekz span {
    color: #fff;
    font-family: Montserrat;
    font-size: 3.5vw;
    text-align: center;
    padding: 3vw 6vw;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    margin-top: 0.5vw;
    display: block;
  }
  .houseImg {
    display: none;
  }
  .sliderNew {
    width: 94%;
    margin: 0 auto;
    margin-top: 65vw;
  }
  .carousel-dots li {
    border: none !important;
  }
  .house .carousel-dots {
    width: fit-content !important;
    margin: 4vw auto;
    backdrop-filter: blur(5px);
    padding: 3vw;
    border-radius: 2vw !important;
    border: 2px solid #fff !important;
    overflow: hidden;
    -webkit-box-shadow: -1px -1px 8px 0px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: -1px -1px 8px 0px rgba(34, 60, 80, 0.2);
    box-shadow: -1px -1px 8px 0px rgba(34, 60, 80, 0.2);
  }
  .ekz .button {
    color: #fff;
    font-family: Montserrat;
    font-size: 5.1vw;
    margin-left: 0;
    padding: 4.7vw 14.5vw;
    border-radius: 1000px;
    background: #ba9766;
    border-radius: 13vw;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
    text-transform: uppercase;
  }
  .ekz {
    flex-direction: column;
    align-items: center;
  }
  .house h3 {
    color: #fff;
    text-align: center;
    font-family: Montserrat;
    font-size: 9vw;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    text-transform: uppercase;
    text-align: center;
  }
}
