.Summ {
  background: url(./img/fon.png);
  background-size: cover;
  padding-left: 7.5vw;
  padding-top: 6vw;
  padding-bottom: 4vw;
  padding-right: 2vw;
  position: relative;
}
.Summ h3 {
  color: #fff;
  font-family: Montserrat;
  font-size: 5vw;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 96px */
  text-transform: uppercase;
  margin-bottom: 4vw;
}
.Summ h3 span {
  color: #dbb176;
  font-family: Montserrat;
  font-size: 2vw;

  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.summContent {
  display: flex;
  align-items: flex-start;
}
.rent {
  color: #fff;
  font-family: Montserrat;
  font-size: 1.5vw;
  font-style: normal;
  margin-bottom: 1.8vw;
  font-weight: 600;
  line-height: normal;
}
.pric {
  color: #dbb176;

  font-family: Montserrat;
  font-size: 3.2vw;
  margin-bottom: 1vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.kap {
  color: #dbb176;
  font-family: Montserrat;
  font-size: 1.4vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 2vw;
}
.kap span {
  color: #fff;
  font-family: Montserrat;
  font-size: 1.1vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.vozvrat {
  padding: 2vw 1.5vw;
  color: #fff;
  font-family: Montserrat;
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: fit-content;
  background: #7d7ab5;
  margin-bottom: 1vw;
  width: 24vw;
  box-shadow: 13.647px 0px 54.588px 0px rgba(0, 19, 43, 0.25);
}
.vozvrat span {
  color: #c0c3ff;
  font-family: Montserrat;
  font-size: 1.1vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.euro {
  background: #dbb176;
  padding: 1vw;
  color: #fff;

  text-align: center;
  width: 24vw;
  font-family: Montserrat;
  padding-right: 0;
  font-size: 3.5vw;
  font-style: normal;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1vw;
  align-items: center;
  line-height: normal;

  box-shadow: 13.647px 0px 54.588px 0px rgba(0, 19, 43, 0.25);
}
.euro img {
  width: 3.7vw;
  margin-left: 2vw;
}
.Summ li p {
  color: rgba(255, 255, 255, 0.6);
  font-family: Montserrat;
  font-size: 1.1vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.Summ li {
  display: flex;
  justify-content: space-between;
  width: 21vw;
  margin-top: 1.5vw;
  padding: 0;
  border-bottom: 0;
}
.Summ li span {
  color: #fff;
  text-align: center;
  font-family: Montserrat;
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.Summ li div {
  color: #fff;
  font-family: Montserrat;
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.Summ li b {
  color: #fff;
  text-align: center;
  font-family: Montserrat;
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.rightSumm {
  display: flex;
  margin-left: 4vw;
  background: #5c5993;
  padding: 2vw;
  box-shadow: 0px 13.647px 54.588px 0px rgba(0, 19, 43, 0.25);
}
.graf > p {
  color: #fff;
  font-family: Montserrat;
  font-size: 1vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 3vw;
}
.graf > span {
  color: rgba(255, 255, 255, 0.6);
  font-family: Montserrat;
  font-size: 1vw;
  font-style: normal;
  margin-left: 3vw;
  line-height: normal;
}
.grafic {
  display: flex;
  align-items: center;
  margin-bottom: 2vw;
  margin-top: 0.8vw;
}
.graficEm {
  color: #fff;

  font-family: Montserrat;
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 1.5vw 2.5vw;
  margin-right: 1vw;

  box-shadow: 0px 13.647px 54.588px 0px rgba(0, 19, 43, 0.25);
}
.gr1 {
  background: #d9af75;
  width: 38vw;
}
.gr2 {
  background: rgba(125, 122, 181, 0.8);
  width: 26vw;
}
.gr3 {
  background: #847fde;
  width: 29vw;
}
.gr4 {
  background: #6fb075;
  width: 32vw;
}
.grafic p {
  color: #fff;
  text-align: center;
  font-family: Montserrat;
  font-size: 2.5vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.rightSummRight {
  display: flex;
  align-items: center;
  margin-left: 1.5vw;
}
.rightSummRight div {
  margin-left: 1vw;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: -1.8vw;
}
.rightSummRight div p {
  color: #fff;
  text-align: center;
  font-family: Montserrat;
  font-size: 3.4vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.rightSummRight div span {
  color: #fff;
  text-align: center;
  font-family: Montserrat;
  font-size: 1vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.rightSummRight img {
  width: 1.65vw;
  margin-top: 1.3vw;
}
@media (max-width: 640px) {
  .Summ {
    padding: 8vw 3vw;
  }
  .Summ h3 {
    color: #fff;
    font-family: Montserrat;
    font-size: 11vw;
    display: flex;
    flex-direction: column;
    font-style: normal;
    font-weight: 700;
    line-height: 118%;
    text-transform: uppercase;
    margin-bottom: 6vw;
  }
  .Summ h3 span {
    color: #dbb176;
    font-family: Montserrat;
    font-size: 5vw;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .pric {
    color: #dbb176;
    font-family: Montserrat;
    font-size: 9.2vw;
    margin-bottom: 7vw;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .vozvrat {
    padding: 4vw 3.5vw;
    color: #fff;
    font-family: Montserrat;
    font-size: 4.5vw;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: -moz-fit-content;
    width: fit-content;
    background: #7d7ab5;
    margin-bottom: 3vw;
    width: 75vw;
    box-shadow: 13.647px 0px 54.588px 0px rgba(0, 19, 43, 0.25);
  }
  .euro img {
    width: 10.7vw;
    margin-right: 4vw;
  }
  .Summ li span {
    color: #fff;
    text-align: center;
    font-family: Montserrat;
    font-size: 4.1vw;
    font-style: normal;
    font-weight: 400;
    margin-top: 0;
    line-height: normal;
  }
  .Summ li div {
    color: #fff;
    font-family: Montserrat;
    font-size: 4.5vw;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .Summ li b {
    color: #fff;
    text-align: center;
    font-family: Montserrat;
    font-size: 4.5vw;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .Summ li p {
    color: rgba(255, 255, 255, 0.6);
    font-family: Montserrat;
    font-size: 4.1vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .Summ li {
    display: flex;
    justify-content: space-between;
    width: auto;
    margin-top: 5.5vw;
    padding: 0;
    border-bottom: 0;
  }
  .rightSumm {
    display: flex;
    margin-left: 0;
    width: 100%;
    margin-top: 7vw;
    background: #5c5993;
    padding: 3vw;
    box-shadow: 0px 13.647px 54.588px 0px rgba(0, 19, 43, 0.25);
  }
  .graf > p {
    color: #fff;
    font-family: Montserrat;
    font-size: 3.4vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 3vw;
  }
  .grafic {
    display: flex;
    align-items: center;
    margin-bottom: 7vw;
    margin-top: 2.8vw;
  }
  .gr1 {
    background: #d9af75;
    width: 72vw;
  }
  .gr3 {
    background: #847fde;
    width: 60vw;
  }
  .gr4 {
    background: #6fb075;
    width: 66vw;
  }
  .rightSummRight {
    display: none;
  }
  .gr2 {
    background: rgba(125, 122, 181, 0.8);
    width: 54vw;
  }
  .grafic p {
    color: #fff;
    text-align: center;
    font-family: Montserrat;
    font-size: 5.5vw;
    font-style: normal;
    margin-left: 2vw;
    font-weight: 400;
    line-height: normal;
  }
  .graficEm {
    color: #fff;
    font-family: Montserrat;
    font-size: 4.5vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 3.5vw 4.5vw;
    margin-right: 1vw;
    box-shadow: 0px 13.647px 54.588px 0px rgba(0, 19, 43, 0.25);
  }
  .graf > span {
    color: rgba(255, 255, 255, 0.6);
    font-family: Montserrat;
    font-size: 3vw;
    font-style: normal;
    display: block;
    width: 76%;
    margin-top: 0.5vw;
    margin-left: 3vw;
    line-height: normal;
  }
  .euro {
    background: #dbb176;
    padding: 3vw;
    color: #fff;
    text-align: center;
    width: 75vw;
    font-family: Montserrat;
    padding-right: 0;
    font-size: 10.5vw;
    font-style: normal;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    margin-bottom: 5vw;
    align-items: center;
    line-height: normal;
    box-shadow: 13.647px 0px 54.588px 0px rgba(0, 19, 43, 0.25);
  }
  .kap span {
    color: #fff;
    font-family: Montserrat;
    font-size: 4.1vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .kap {
    color: #dbb176;
    font-family: Montserrat;
    font-size: 4vw;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 5vw;
  }
  .rent {
    color: #fff;
    font-family: Montserrat;
    font-size: 4vw;
    font-style: normal;
    margin-bottom: 0.8vw;
    font-weight: 500;
    line-height: normal;
  }
  .summContent {
    flex-direction: column;
  }
  .vozvrat span {
    color: #c0c3ff;
    font-family: Montserrat;
    font-size: 3.1vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .oPalm {
    display: none;
  }
}
