.ojo .topMenu {
  padding: 1vw 2vw;
  background: #484677;
}
.ojo .topMenu > img {
  margin-right: auto;
}
.bigO {
  width: 94vw;
  display: block;
  margin: 2vw auto;
}
.ojo h3 {
  color: #525252;
  font-family: Montserrat;
  font-size: 5vw;
  margin-top: 5vw;
  margin-bottom: 5vw;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 105.6px */
  text-transform: uppercase;
}
.ojo h4 {
  color: #3f4245;
  font-family: Montserrat;
  font-size: 3.2vw;
  font-style: normal;
  text-align: center;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 3vw;
  margin-top: 1vw;
}
.popMenu a {
  text-align: center;
  display: flex;
  justify-content: center;
  text-decoration: none;
  width: 100%;
}
.videos iframe {
  margin: 0 auto;
  display: block;
  width: 100%;
  height: 45.3vw;
  margin-top: 2vw;
  margin-bottom: 2vw;
}

.ultra {
  cursor: pointer;
  margin-left: 6vw;
  text-transform: uppercase;
  font-size: 0.7vw !important;
  font-weight: 500 !important;
}
.ojo .button {
  color: #fff;
  font-family: Montserrat;
  font-size: 1.2vw;
  font-style: normal;
  border-radius: 1051.18px;
  margin: 0 auto;
  background: #ba9766;
  padding: 2vw 5vw;
  width: fit-content;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 3vw;
}
.button:hover {
  background: #715c3f;
  cursor: pointer;
}
.ahaha {
  width: 94vw;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  margin-bottom: 3vw;
}
.ahaha img {
  height: 33vw;
}
@media (max-width: 640px) {
  .topMenu > img {
    display: none;
  }
  .burgermenu {
    height: 8.5vw !important;
  }
  .ojo .topMenu {
    padding: 2vw 3vw;
  }
  .objjSlider {
    margin: 5vw auto;
    margin-top: 8vw;
    width: 94vw;
  }
  .ultra {
    display: block !important;
    font-size: 2vw !important;
    margin-left: 9vw !important;
  }
  .ojo h4 {
    color: #3f4245;
    font-family: Montserrat;
    font-size: 7.2vw;
    font-style: normal;
    text-align: center;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 5vw;
    margin-top: 13vw;
  }
  .ojo h3 {
    color: #525252;
    font-family: Montserrat;
    font-size: 9vw;
    margin-top: 10vw;
    margin-bottom: 5vw;
    text-align: center;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    text-transform: uppercase;
  }
  .objjSlider > div {
    width: 100%;
  }
  .objjSlider .carousel-item {
    width: 100% !important;
  }
  .ahaha {
    flex-direction: column;
  }
  .ahaha img {
    width: 100%;
    height: auto;
  }
  .kukus {
    margin: 5vw auto;
    display: flex;
    justify-content: space-between;
  }
  .kukus img {
    width: 47%;
  }
  .ojo .button {
    color: #fff;
    font-family: Montserrat;
    font-size: 3.2vw;
    font-style: normal;
    border-radius: 1051.18px;
    margin: 0 auto;
    background: #ba9766;
    padding: 5vw 10vw;
    width: -moz-fit-content;
    width: fit-content;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 8vw;
  }
}
