.headerContent {
  display: flex;
}
.headerRight {
  background: #484677;
  width: 56.9%;
  position: relative;
  padding: 3vw 9.5vw;
  padding-right: 2vw;
}
.leftHeader img {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}
.ololol {
  width: 45vw;
  height: 45vw;
}
video {
  display: block;
}
.leftHeader > div {
  height: 100%;
}
.popMenu p {
  color: #fff;
  font-size: 1vw;
  padding: 1.5vw 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  width: 60%;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
}
.popMenu a:last-child p {
  border-bottom: none;
}
.popMenu p:hover {
  color: #dbb176;
}
#aaaaaaaa p {
  border-bottom: none;
}
.popMenu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30vw;
  position: relative;
  background: #363644;
  height: 100%;
}
body {
  transition: 0;
}
.pop {
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  width: 0;
  transition: 0.5s;
  overflow: hidden;
  z-index: 500;
  height: 0;

  width: 100vw;
  height: 100vh;
  z-index: 400;
}
.leftHeader {
  width: 43.1%;
}
.logo {
  position: absolute;
  top: 18.5vw;
  width: 10vw;
  left: -5.2vw;
}
.topMenu {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.topMenu p {
  color: #fff;

  font-family: Montserrat;
  font-size: 0.7vw;
  font-style: normal;
  font-weight: 400;
  margin-right: 1vw;
  line-height: normal;
}
.keks img {
  width: 1.5vw;
  margin-right: 1vw;
}
.tel {
  color: #fff;
  font-family: Montserrat;
  font-size: 1.3vw;
  text-decoration: none;
  font-style: normal;
  font-weight: 400;
  line-height: 133.023%; /* 31.925px */
}
.frist1 {
  width: 2.2vw;
  height: 0.25vw;
  border-radius: 4vw;
  background: #fff;
}
.frist2 {
  width: 1.7vw;
  height: 0.25vw;
  border-radius: 4vw;
  background: #fff;
}
.frist3 {
  width: 1.2vw;
  height: 0.25vw;
  border-radius: 4vw;
  background: #fff;
}
.burgermenu div {
  margin: 0.25vw 0;
  transition: 0.3s;
}
.burgermenu {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: 6vw;
  position: relative;
  z-index: 500;
  height: 2.5vw;
}
@media (min-width: 640px) {
  .burgermenu:hover div {
    width: 2.2vw !important;
  }
}
h1 {
  color: #fff;
  font-family: Montserrat;
  font-size: 3.5vw;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 94.55px */
  text-transform: uppercase;
  margin-top: 4vw;
}
h1 span {
  color: #dbb176;
}
.zastr {
  color: #fff;
  font-family: Montserrat;
  font-size: 1.5vw;
  font-style: normal;
  margin-top: 1vw;
  font-weight: 400;
  line-height: 100%; /* 32.482px */
  letter-spacing: 4.872px;
}
.green {
  padding: 1vw 1.5vw;
  background: #6fb075;
  color: #fff;
  font-family: Montserrat;
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  width: fit-content;
  margin: 2.7vw 0;
}
.green b {
  font-weight: 600;
}
.lineDiv {
  display: flex;
  align-items: center;
}
.line {
  background: rgba(219, 177, 118, 1);
  height: 3px;
  width: 4.5vw;
  margin-right: 1.4vw;
}
.lineDiv p {
  color: #fff;
  font-family: Montserrat;
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.ogr {
  color: #fff;
  font-family: Montserrat;
  font-size: 1vw;
  margin-top: 2.5vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 5.372px;
  text-transform: uppercase;
}
.podr {
  width: 11vw;
  height: 11vw;
  border-radius: 50%;
  background: #ba9766;
  text-align: center;
  color: #fff;
  font-family: Montserrat;
  font-size: 1.2vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1.9px;
  text-transform: uppercase;
  position: absolute;
  bottom: -5.3vw;
  right: 10vw;
  z-index: 5;
  filter: drop-shadow(0px 20px 50px rgba(178, 141, 89, 0.4));
  cursor: pointer;
  animation: shark 4s linear infinite;
}

.podr:hover {
  scale: 1.1;
}
@media (max-width: 640px) {
  .headerContent {
    flex-direction: column-reverse;
  }
  .headerRight {
    width: 100%;
    padding-bottom: 15vw;
  }
  .popMenu {
    width: 70%;
    padding-bottom: 0vw;
    margin-top: 0vw;
    z-index: 500;
  }
  .topMenu p {
    display: none;
  }
  .keks img {
    width: 6.5vw;
    margin-right: 3vw;
  }
  .tel {
    color: #fff;
    font-family: Montserrat;
    font-size: 4.3vw;
    text-decoration: none;
    font-style: normal;
    font-weight: 400;
    line-height: 133.023%;
  }
  .frist1 {
    width: 8.2vw;
    height: 0.5vw;
    border-radius: 4vw;
    background: #fff;
  }
  .burgermenu div {
    margin: 1vw 0;
    transition: 0.3s;
  }
  .burgermenu {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-left: auto;
    position: relative;
    z-index: 500 !important;
  }

  .popMenu p {
    color: #fff;
    font-size: 3vw;
    padding: 4.5vw 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    width: 90%;
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
    cursor: pointer;
  }
  .frist2 {
    width: 6.2vw;
    height: 0.5vw;
    border-radius: 4vw;
    background: #fff;
  }
  .zastr {
    color: #fff;
    font-family: Montserrat;
    font-size: 3.5vw;
    font-style: normal;
    margin-top: 3vw;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: 2.872px;
  }
  .green {
    padding: 2vw 3vw;
    background: #6fb075;
    color: #fff;
    font-family: Montserrat;
    font-size: 4vw;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    width: -moz-fit-content;
    width: fit-content;
    margin: 5.7vw 0;
  }
  .lineDiv p {
    color: #fff;
    font-family: Montserrat;
    font-size: 4.2vw;
    font-style: normal;
    margin-top: 3vw;
    font-weight: 600;
    line-height: normal;
  }
  .ogr {
    color: #fff;
    font-family: Montserrat;
    font-size: 2.8vw;
    margin-top: 8vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.372px;
    text-transform: uppercase;
  }
  .line {
    background: rgba(219, 177, 118, 1);
    height: 1px;
    width: 15.5vw;
    margin-right: 0;
  }
  .lineDiv {
    flex-direction: column;
  }
  h1 {
    color: #fff;
    text-align: center;
    font-family: Montserrat;
    font-size: 10.5vw;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    text-transform: uppercase;
    margin-top: 103vw;
  }
  .leftHeader {
    width: 100%;
  }
  .leftHeader > div {
    height: auto;
  }
  .logo {
    position: absolute;
    top: 79.5vw;
    width: 28vw;
    left: 37%;
    z-index: 6;
  }
  .ololol {
    width: 100%;
    height: 45vw;
    position: absolute;
    top: 13vw;
    z-index: 4;
  }
  .podr {
    width: 35vw;
    height: 35vw;
    border-radius: 50%;
    background: #ba9766;
    color: #fff;
    font-family: Montserrat;
    font-size: 4vw;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1.9px;
    text-transform: uppercase;
    position: absolute;
    bottom: -17.3vw;
    right: 32vw;
    cursor: pointer;
  }
  .headerRight {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 2vw;
    padding-bottom: 30vw;
  }
  .topMenu {
    width: 100%;
  }
  .frist3 {
    width: 4.2vw;
    height: 0.5vw;
    border-radius: 4vw;
    background: #fff;
  }
  .leftHeader {
    height: auto;
  }
}
@keyframes shark {
  0% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(-5px);
  }
  40% {
    transform: translateY(0);
  }
  60% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(0);
  }
}
