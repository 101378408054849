@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  transition: 0.3s;
}
::-webkit-scrollbar {
  width: 12px; /* ширина для вертикального скролла */
  /* высота для горизонтального скролла */
  background-color: rgba(32, 104, 173, 0.12);
  transition: 0.3s;
}

/* ползунок скроллбара */
::-webkit-scrollbar-thumb {
  background-color: #ba9766;
}
@keyframes palm {
  0% {
  }
  50% {
    transform: scale(1, 0.95) translateY(-5px);
  }
  100% {
    transform: scale(1, 1) translateY(0);
  }
}
#root {
  overflow: hidden;
}
.preloader {
  width: 100vw;
  height: 100vh;
  background: #484677;
  background-size: 100%;
  font-family: "Montserrat", sans-serif;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;

  z-index: 1000;
  transform-style: preserve-3d;
  perspective: 800px;
}
body {
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
}

.loading-container {
  width: 100%;
  max-width: 520px;
  text-align: center;
  color: #fff;
  position: relative;
  margin: 0 32px;
}
.loading-container:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  background-color: #fff;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 10px;
  animation: movingLine 2.4s infinite ease-in-out;
}

@keyframes movingLine {
  0% {
    opacity: 0;
    width: 0;
  }
  33.3%,
  66% {
    opacity: 0.8;
    width: 100%;
  }
  85% {
    width: 0;
    left: initial;
    right: 0;
    opacity: 1;
  }
  100% {
    opacity: 0;
    width: 0;
  }
}
.loading-text {
  font-size: 5vw;
  line-height: 64px;
  letter-spacing: 10px;
  margin-bottom: 32px;
  display: flex;
  justify-content: space-evenly;
}
.loading-text span {
  animation: moveLetters 2.4s infinite ease-in-out;
  transform: translatex(0);
  position: relative;
  display: inline-block;
  opacity: 0;
  text-shadow: 0px 2px 10px rgba(46, 74, 81, 0.3);
}
.ero {
  text-align: center;
  color: red;
  margin-bottom: 5px;
}
.form {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.42);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  z-index: 99999999;
}
.formBody {
  background: url(./pop.png);
  background-size: cover;
  background-position: center;
  padding: 2vw;
  display: flex;
  flex-direction: column;
}
.formBody h3 {
  color: #fff;
  text-align: center;
  font-family: Montserrat;
  font-size: 2.5vw;
  margin-bottom: 1.5vw;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 52.8px */
  text-transform: uppercase;
}
.formBody input {
  padding: 1.5vw 2vw;
  font-size: 1vw;
  background: #fff;
  border: none;
  border-radius: 1000px;
  width: 20vw;
  margin-bottom: 1.5vw;
}
.flare {
  animation: flareAnimation;
  animation: flareAnimation 5s linear infinite;
  background: linear-gradient(90deg, #ffffff1a, #fff6);
  height: 100%;
  left: -150%;
  position: absolute;
  top: 0;
  transform: skewX(-45deg);
  width: 45px;
}
.pr,
.button {
  position: relative;
  overflow: hidden;
}
@keyframes flareAnimation {
  0% {
    left: -200%;
  }
  100% {
    left: 150%;
  }
}
.formBody div {
  color: #fff;
  font-family: Montserrat;
  font-size: 0.9vw;
  width: 20vw;
  text-align: center;
  padding: 1.5vw;
  font-style: normal;
  font-weight: 600;
  background: #ba9766;
  border-radius: 1000px;
  line-height: normal;
  text-transform: uppercase;
}
.loading-text span:nth-child(1) {
  animation-delay: 0.1s;
}
.loading-text span:nth-child(2) {
  animation-delay: 0.2s;
}
.loading-text span:nth-child(3) {
  animation-delay: 0.3s;
}
.loading-text span:nth-child(4) {
  animation-delay: 0.4s;
}
.loading-text span:nth-child(5) {
  animation-delay: 0.5s;
}
.loading-text span:nth-child(6) {
  animation-delay: 0.6s;
}
.loading-text span:nth-child(7) {
  animation-delay: 0.7s;
}
@keyframes moveLetters {
  0% {
    transform: translateX(-15vw);
    opacity: 0;
  }
  33.3%,
  66% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(15vw);
    opacity: 0;
  }
}
.socials {
  position: fixed;
  bottom: 16px;
  right: 16px;
  display: flex;
  align-items: center;
}
.social-link {
  color: #fff;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  margin-right: 12px;
}
.vec {
  width: 180px;
  top: 215px;
  animation: logo 2s forwards;
}
@keyframes logo {
  0% {
    transform: translateY(-40px) rotateY(0deg) scale(1.5);
  }
  100% {
    transform: translateY(0px) rotateY(360deg) scale(1);
  }
}
.carousel-dots li button:before {
  font-family: "slick";
  font-size: 40px;
  line-height: 20px;

  position: absolute;
  top: 0;
  left: 0;

  width: 20px;
  height: 20px;
  background: black;
  border-radius: 50% !important;
  content: "" !important;
  text-align: center;

  opacity: 0.25;
  color: black;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.preloader {
  transition: 0.5s linear;
}

.xit {
  margin-bottom: 1.5vw;
}
.tito {
  margin-bottom: 1.5vw;
}
.carousel-dots li.carousel-dots-active button:before {
  opacity: 1 !important;
  background: #dbb176 !important;
}
.timerPopBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  background-image: url(./img/palm.png);
  background-position-y: 14vw;
  background-size: 100%;
  background-repeat: no-repeat;
  padding: 1.5vw;
  position: relative;
}
.sliden {
  width: 90vw;
  margin: 2vw auto;
  margin-bottom: 4vw;
  position: relative;
}
.sliden > img {
  width: 100%;
  margin-bottom: 2vw;
  border-radius: 1.5vw;
}
.loader {
  width: 2.5vw;
  height: 2.5vw;
  border: 0.3vw solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  position: absolute;
  left: 45%;
  top: 2.2vw;
}
.timerPopBody {
  position: relative;
}
.timerPopBody .loader {
  top: 12vw;
  width: 4vw;
  border: 0.5vw solid #fff;
  border-bottom-color: transparent;
  height: 4vw;
  left: 43.5%;
}
.formBody {
  position: relative;
}
.amzB {
  display: flex;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.lefts,
.rights {
  position: absolute;
  top: 33%;
  width: 5vw;
  height: 5vw;

  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2vw;
  line-height: 1vw;
  cursor: pointer;

  transition: 0.3s;
}
.lefts:hover,
.rights:hover {
  transform: scale(1.1);
}
.lefts {
  left: 2vw;
}
.rights {
  right: 2vw;
}
.rights img,
.lefts img {
  width: 100%;
}
.lefts img {
  rotate: 180deg;
}
.slPer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.slPer img {
  width: 18%;
  cursor: pointer;
  transition: 0.2s;
}
.timerPopBody img {
  width: 24.83974358974359vw;
}
.timerPopBody input {
  border-radius: 75.429px;
  background: #f2eeec;
  padding: 1.5vw;
  margin-bottom: 1.5vw;
  width: 100%;
  border: none;
  font-size: 1vw;
  outline: none;
}
.joker {
  display: flex;
  margin-bottom: 1.5vw;
}
.joker input {
  margin-bottom: 0;
  width: 1.2vw;
  height: 1.2vw;
  margin-right: 0.5vw;
}
.timerPop .button {
  color: #fff;
  font-family: Montserrat;
  font-size: 0.9vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 100%;
  border-radius: 1000px;
  background: #ba9766;
  padding: 1.5vw;
  border: none;
  text-align: center;
  text-transform: uppercase;
}
.joker div {
  display: flex;
  align-items: center;
  margin: 0 1vw;
}
.joker label {
  color: #525252;
  font-family: Montserrat;
  font-size: 0.8vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}
.timerPop {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.42);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  z-index: 99999;
}
.otkaz {
  width: 60%;
  margin: 4vw auto;
}
.otkaz h4 {
  text-align: center;
  font-size: 40px;
}
.formBody div {
  cursor: pointer;
}
.thanks {
  background: #484677;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.thanks img {
  width: 37vw;
}
.thanks p {
  text-align: center;
  color: rgba(255, 255, 255, 1);
  font-family: Montserrat;
  font-size: 1.4vw;
  margin-top: 3vw;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
}
@media (min-width: 640px) {
  .timerPopBody {
    position: relative;
    transform: scale(0.8);
  }
}
.closly {
  position: absolute;
  top: -1vw;
  right: -1vw;
  background: #ba9766;
  width: 3vw !important;
  padding: 0.3vw;
  cursor: pointer;
}
.thanks h4 {
  color: #dbb176;
  font-family: Montserrat;
  font-size: 6vw;
  margin-bottom: 4vw;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 141.6px */
  text-transform: uppercase;
}
.formBody > img {
  width: 3vw;
  display: block;
  margin-left: auto;
  margin-bottom: 1.5vw;
  cursor: pointer;
}
@media (max-width: 640px) {
  .formBody {
    padding: 8vw;
  }
  .otkaz {
    width: 90%;
    margin: 4vw auto;
  }
  .loader {
    width: 14vw;
    height: 14vw;
    top: 8vw;
    border-width: 1vw;
    left: 41%;
  }
  .timerPopBody .loader {
    width: 14vw;
    height: 14vw;
    top: 35vw;
    border-width: 1vw;
    border-width: 1vw;
    left: 41%;
  }
  .thanks h4 {
    color: #dbb176;
    font-family: Montserrat;
    font-size: 11vw;
    margin-bottom: 10vw;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    text-transform: uppercase;
  }
  .timerPopBody img {
    width: 100%;
  }
  .tito {
    margin-bottom: 6vw;
  }
  .timerPopBody input {
    border-radius: 75.429px;
    background: #f2eeec;
    padding: 4.5vw;
    margin-bottom: 3.5vw;
    width: 100%;
    border: none;
    font-size: 4vw;
    outline: none;
  }
  .timerPopBody {
    padding: 6vw;
    width: 85vw;
    background-position-y: 45vw;
  }
  .closly {
  }
  .joker div {
    display: flex;
    align-items: center;
    margin: 0 4vw;
  }
  .joker {
    margin-bottom: 4vw;
  }
  .timerPop .button {
    color: #fff;
    font-family: Montserrat;
    font-size: 3.9vw;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: 100%;
    border-radius: 1000px;
    background: #ba9766;
    padding: 5.5vw;
    border: none;
    text-align: center;
    text-transform: uppercase;
  }
  .joker label {
    color: #525252;
    font-family: Montserrat;
    font-size: 2.8vw;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
  }
  .joker input {
    margin-bottom: 0;
    width: 6.2vw;
    height: 6.2vw;
    margin-right: 1.5vw;
  }
  .formBody > img {
    width: 13vw;
    margin-right: -3vw;
    display: block;
    margin-left: auto;
    margin-bottom: 6.5vw;
    cursor: pointer;
  }
  .carousel-arrow {
    position: absolute !important;
    top: auto !important;
    bottom: -5vw;
    height: 15vw !important;
    background: #fff !important;
    width: 15vw !important;
  }
  .closly {
    position: absolute;
    top: -7vw;
    right: 0vw;
    background: #ba9766;
    width: 10vw !important;
    padding: 0.3vw;
    cursor: pointer;
  }
  .thanks p {
    text-align: center;
    color: rgba(255, 255, 255, 1);
    font-family: Montserrat;
    font-size: 4.5vw;
    width: 84vw;
    margin-top: 3vw;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
  }
  .thanks img {
    width: 80vw;
  }
  .formBody h3 {
    color: #fff;
    text-align: center;
    font-family: Montserrat;
    font-size: 9.5vw;
    margin-bottom: 6.5vw;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    text-transform: uppercase;
  }
  .formBody div {
    color: #fff;
    font-family: Montserrat;
    font-size: 3.9vw;
    width: 100%;
    text-align: center;
    padding: 4.5vw;
    font-style: normal;
    font-weight: 600;
    background: #ba9766;
    border-radius: 1000px;
    line-height: normal;
    text-transform: uppercase;
  }
  .formBody input {
    padding: 4.5vw 6vw;
    font-size: 4vw;
    background: #fff;
    border: none;
    border-radius: 1000px;
    width: 100%;
    margin-bottom: 6.5vw;
  }
}
