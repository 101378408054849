.navic {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  margin-top: 1vw;
}
.navic div {
  display: flex;
  justify-content: center;
}
.navic img {
  margin: 0 0.5vw;
  width: 2.5vw;
}
.navic p {
  border: none;
  padding-bottom: 1vw;
}
.navic p:hover {
  color: #fff;
  cursor: unset;
}
@media (max-width: 640px) {
  .navic img {
    width: 8vw;
    margin: 0 2.5vw;
  }
  .navic p {
    border: none;
    padding-bottom: 3vw;
  }
}
