.sliderApp {
  width: 94vw;
  margin: 0 auto;

  overflow-x: scroll;
  padding: 3vw 0;
  display: flex;
}
.stat {
  padding-top: 4vw;
}
.sliderAppEm {
  flex-direction: column;
  border: 1.601px solid #e7d8cf;
  background: #fff;
  padding: 2vw 1vw;
  width: 30vw;
  min-width: 30vw;
  margin-right: 2vw;
}
.sliderAppEm img {
  width: 100%;
  position: relative;
  top: -4vw;
}
.sliderAppEm h4 {
  color: #525252;
  font-family: Montserrat;
  font-size: 1.5vw;
  margin-bottom: 1vw;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 33.6px */
  text-transform: uppercase;
}
.sliderAppEm li p {
  color: #525252;
  font-family: Montserrat;
  font-size: 1vw;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 27px */
}
.sliderAppEm li {
  border-bottom: none;
  padding: 0.3vw 0;
}
.mart {
  margin-top: -3vw;
}
.sliderPrice {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1vw;
}
.sliderAppEm li div {
  color: #ba9766;
  margin-right: 0.5vw;
  font-size: 1vw;
}
.sliderPrice p {
  color: #ba9766;
  font-family: Montserrat;
  font-size: 1.3vw;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 36px */
}
.sliderPrice .button {
  color: #fff;
  border-radius: 800.681px;
  background: #ba9766;
  font-family: Montserrat;
  font-size: 1vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  padding: 1.3vw 3.5vw;
}
.statCont {
  display: flex;
}
.statNext {
  display: flex;
  align-items: flex-start;
  margin-top: -2vw;
}
.statCont > img {
  width: 43vw;
  margin-right: 3.4vw;
  margin-left: 4vw;
  z-index: 2;
}
.mounth {
  background: #fef7f2;
  padding: 2.7vw;
  position: relative;
  margin-bottom: 4vw;
}
.mounth div {
  display: flex;
}
.mounth div p {
  color: #525252;
  font-family: Montserrat;
  font-size: 2.5vw;
  font-style: normal;
  display: flex;
  flex-direction: column;
  font-weight: 500;
  line-height: 140%; /* 89.6px */
  text-transform: uppercase;
}
.mounth div span {
  color: #525252;
  font-family: Montserrat;
  font-size: 0.8vw;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 22.4px */
  text-transform: uppercase;
}
.statRight img {
  width: 47vw;
}
.cce {
  padding: 0 1.5vw;
  margin: 0 1.5vw;
  border-right: 2px solid #e0d9d5;
  border-left: 2px solid #e0d9d5;
}
.mounth > p {
  background: #ba9766;
  padding: 0.5vw 1vw;
  color: #fff;
  font-family: Montserrat;
  font-size: 1.6vw;
  position: absolute;
  top: -1.8vw;
  width: fit-content;
  left: 0vw;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 44.8px */
  text-transform: uppercase;
}
.mounth {
  margin-top: 1.8vw;
}
.statNext p {
  background: #fef7f2;
  padding: 1.5vw;
  padding-top: 4.5vw;
  color: #525252;
  font-family: Montserrat;
  font-size: 1vw;
  font-style: normal;
  font-weight: 400;
  width: 53vw;
  margin-left: 8vw;
  line-height: 180%; /* 32px */
  margin-right: 2vw;
}
.kaka {
  display: block;
  margin-bottom: 2vw;
}
.pis {
  width: 88vw;
  display: block;
  margin: 1vw 0;
  margin-left: 8vw;
  margin-bottom: 7vw;
}
.statNext p span {
  font-weight: bold;
  display: block;
  margin-bottom: 0.5vw;
}
.statNext p b {
  color: #ba9766;
}
.statNext img {
  width: 33vw;
}
.statNext {
  margin-bottom: 1vw;
}
@media (max-width: 640px) {
  .sliderApp > div {
    width: 100%;
    padding: 8vw 0;
  }
  .statCont {
    flex-direction: column;
  }
  .statNext {
    margin-bottom: 11vw;
  }
  .stat {
    padding: 6vw 3vw;
  }
  .pis {
    width: 88vw;
    display: block;
    margin: 1vw 0;
    margin-left: 0vw;
    margin-bottom: 0vw;
  }
  .kaka {
    display: block;
    margin: 5vw 0;
  }
  .statCont > img {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
    z-index: 2;
  }
  .mounth > p {
    background: #ba9766;
    padding: 1.5vw 3vw;
    color: #fff;
    font-family: Montserrat;
    font-size: 5.6vw;
    position: absolute;
    top: -4.8vw;
    width: -moz-fit-content;
    width: fit-content;
    left: 0vw;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    text-transform: uppercase;
  }
  .mounth {
    background: #fef7f2;
    padding: 4.7vw;
    position: relative;
    margin-bottom: 4vw;
    padding-top: 10vw;
  }
  .mounth div span {
    color: #525252;
    font-family: Montserrat;
    font-size: 2.5vw;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    text-transform: uppercase;
  }
  .sliderAppEm {
    flex-direction: column;
    border: 1.601px solid #e7d8cf;
    background: #fff;
    padding: 4vw 3vw;
    width: 94vw;
    min-width: 94vw;
    margin-right: 4vw;
  }
  .sliderApp .carousel-initialized {
    padding-top: 12vw !important;
  }
  .carousel-track {
    height: 116vw;
  }
  .sliderApp {
    padding-top: 12vw;
    overflow-x: auto;
  }
  .sliderAppEm li {
    border-bottom: none;
    padding: 1.3vw 0;
  }
  .sliderPrice .button {
    color: #fff;
    border-radius: 800.681px;
    background: #ba9766;
    font-family: Montserrat;
    font-size: 3.5vw;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    padding: 4.3vw 7.5vw;
  }
  .sliderPrice {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 4vw;
  }
  .sliderPrice p {
    color: #ba9766;
    font-family: Montserrat;
    font-size: 4vw;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
  }
  .sliderAppEm h4 {
    color: #525252;
    font-family: Montserrat;
    font-size: 6.5vw;
    margin-bottom: 2.5vw;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    text-transform: uppercase;
  }
  .sliderAppEm li div {
    color: #ba9766;
    margin-right: 2.5vw;
    font-size: 5vw;
  }
  .sliderAppEm li p {
    color: #525252;
    font-family: Montserrat;
    font-size: 4vw;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
  }
  .mart {
    margin-top: -12vw;
  }
  .sliderAppEm img {
    width: 100%;
    position: relative;
    top: -16vw;
  }
  .statNext p {
    background: #fef7f2;
    padding: 5.5vw;
    padding-top: 4.5vw;
    color: #525252;
    font-family: Montserrat;
    font-size: 3vw;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    width: 100%;
    margin-left: 0;
    line-height: 160%;
    margin-right: 0;
  }
  .statNext img {
    width: 100%;
    margin-top: 4vw;
  }
  .statNext {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 3vw;
  }
  .statRight img {
    width: 100%;
  }
  .cce {
    padding: 0 2.5vw;
    margin: 0 2.5vw;
    border-right: 2px solid #e0d9d5;
    border-left: 2px solid #e0d9d5;
  }
  .mounth {
    margin-top: 12.8vw;
  }
  .mounth div p {
    color: #525252;
    font-family: Montserrat;
    font-size: 7.6vw;
    font-style: normal;
    display: flex;
    flex-direction: column;
    font-weight: 500;
    line-height: 140%;
    text-transform: uppercase;
  }
}
