.palmL {
  position: absolute;
  top: 20vw;
  left: 0;
  width: 24vw;
  animation: palm 6s infinite;
}
.Live {
  position: relative;
  overflow: hidden;
  display: flex;
  padding-left: 7vw;
  padding-top: 4vw;
}
.palmR {
  position: absolute;
  right: 0;
  top: -14vw;
  width: 13vw;
  animation: palm 5s infinite;
}
.small {
  width: 34.8vw;
  position: relative;
  z-index: 2;
}
.liveRight {
  position: relative;
  left: -2vw;
}
.big {
  display: block;
  margin-left: 20vw;
  margin-top: -22vw;
  width: 40vw;
}
.Live h2 {
  color: #525252;
  font-family: Montserrat;
  font-size: 5vw;
  margin-top: 4vw;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 105.6px */
  text-transform: uppercase;
}
ul {
  list-style: none;
}
.Live ul {
  margin-left: 8vw;
  margin-top: 7vw;
}
ul li {
  display: flex;
  align-items: center;

  padding: 1.5vw 0;
  border-bottom: 1px solid #f4eaea;
}
ul li:last-child {
  border-bottom: none;
}
ul li img {
  width: 3.2vw;
  margin-right: 1.5vw;
}
ul li div p {
  color: #525252;
  font-family: Montserrat;
  font-size: 1.5vw;
  font-style: normal;
  width: 20vw;
  font-weight: 700;
  line-height: 100%; /* 28.247px */
  margin-bottom: 0.5vw;
}
ul li span {
  color: #525252;
  font-family: Montserrat;
  font-size: 1.1vw;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 32.023px */
}
.LiveMob {
  display: none;
}
@media (max-width: 640px) {
  .Live {
    flex-direction: column;
  }
  .Live h2 {
    color: #525252;
    font-family: Montserrat;
    font-size: 10vw;
    margin-top: 5vw;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    text-transform: uppercase;
  }
  .palmR {
    display: none;
  }
  ul li div p {
    color: #525252;
    font-family: Montserrat;
    font-size: 5.5vw;
    font-style: normal;
    width: auto;
    font-weight: 700;
    line-height: 100%;
    margin-bottom: 0.5vw;
  }
  .Live ul {
    margin-left: 0vw;
    margin-top: 7vw;
  }
  ul li img {
    width: 11.2vw;
    margin-right: 5vw;
  }
  ul li {
    display: flex;
    align-items: center;
    padding: 4.5vw 0;
    border-bottom: 1px solid #f4eaea;
  }
  ul li span {
    color: #525252;
    font-family: Montserrat;
    font-size: 4.1vw;

    font-style: normal;
    display: block;
    margin-top: 3vw;
    font-weight: 400;
    line-height: 150%;
  }
  .palmL {
    display: none;
  }
  .Live {
    padding-left: 3vw;
    padding-right: 3vw;
  }
  .LiveMob {
    width: 100%;
    margin-bottom: 10vw;
    display: block;
    margin-top: 9vw;
  }
  .liveRight {
    display: none;
  }
  .rightInfr img {
    display: none;
  }
  .bl {
    width: 100%;
    justify-content: space-between;
  }
  .leftInfr {
    width: 100%;
  }
  .InfrContent {
    flex-direction: column;
  }
  .bl .blocc {
    width: -moz-fit-content;
    width: fit-content;
    padding: 2vw 1.5vw;
    height: 40vw;
    width: 30%;
    display: flex;
    margin-right: 0;
    flex-direction: column;
    align-items: center;
    border: 2.751px solid rgba(157, 169, 153, 0.14);
  }
}
