.footer {
  display: flex;
  background: #484677;
  padding-left: 7vw;
  color: #fff;
  justify-content: space-between;
}
.leftFooter {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.leftFooter img {
  display: block;
  margin-top: 5vw;
  width: 5vw;
  margin-bottom: 3vw;
  cursor: pointer;
}
.leftFooter a {
  text-decoration: none;
  color: #fff;
}
.telFooter {
  color: #fff;
  text-align: center;

  font-size: 2vw;
  font-style: normal;
  font-weight: 600;
  line-height: 120.9%; /* 48.36px */
  text-transform: uppercase;
}
.leftFooter > span {
  font-size: 1vw;
  margin-bottom: 2.5vw;
}
.leftFooter p {
  color: #fff;
  font-family: Montserrat;
  font-size: 1.2vw;
  font-style: normal;
  font-weight: 500;
  line-height: 120.9%; /* 29.016px */
  text-transform: uppercase;
  margin-bottom: 2.5vw;
}
.popsf {
  color: #fff;
  text-align: center;
  font-family: Montserrat;
  font-size: 0.7vw;
  margin-bottom: 2vw;
  font-style: normal;
  font-weight: 500;
  line-height: 120.9%; /* 16.926px */
  text-transform: uppercase;
}
@media (max-width: 640px) {
  .footer {
    padding-left: 0;
    flex-direction: column;
    align-items: center;
  }
  .leftFooter {
    align-items: center;
    margin-bottom: 8vw;
  }
  .leftFooter img {
    display: block;
    margin-top: 11vw;
    width: 20vw;
    margin-bottom: 9vw;
  }
  .leftFooter > span {
    font-size: 4vw;
    margin-bottom: 9.5vw;
  }
  .leftFooter p {
    color: #fff;
    font-family: Montserrat;
    font-size: 4vw;
    font-style: normal;
    font-weight: 500;
    line-height: 120.9%;
    text-transform: uppercase;
    margin-bottom: 8.5vw;
  }
  .popsf {
    color: #fff;
    text-align: center;
    font-family: Montserrat;
    font-size: 2.7vw;
    margin-bottom: 6vw;
    font-style: normal;
    font-weight: 500;
    line-height: 120.9%;
    text-transform: uppercase;
  }
  .telFooter {
    color: #fff;
    text-align: center;
    font-size: 6vw;
    font-style: normal;
    font-weight: 600;
    line-height: 120.9%;
    text-transform: uppercase;
  }
}
