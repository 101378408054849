.support h3 {
  color: #525252;
  font-family: Montserrat;
  font-size: 5vw;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 115.2px */
  text-transform: uppercase;
}
.support {
  position: relative;
  padding-left: 2vw;
  padding-bottom: 3vw;
  margin-top: 3vw;
}
.kolk {
  position: absolute;
  right: 0;
  top: -7vw;
  width: 44vw;
  z-index: 2;
}
.support > p {
  color: #525252;
  font-family: Montserrat;
  font-size: 1.6vw;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  text-transform: uppercase;
  margin-bottom: 1vw;
}
.support > p span {
  color: #ba9766;
  margin-bottom: 4vw;
}
.thf p {
  color: #525252;
  font-family: Montserrat;
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 500;

  line-height: 140%; /* 39.2px */
  text-transform: uppercase;
}
.thf span {
  color: #ba9766;

  font-weight: 700;
  line-height: 140%;
  text-transform: uppercase;
}
.thf {
  display: flex;
  align-items: center;
  margin-top: 4vw;
  margin-bottom: 4vw;
  background: #fff6ea;
}
.thf b {
  color: #fff;
  font-family: Montserrat;
  background: #ba9766;
  font-size: 5vw;
  padding: 0.5vw 1.5vw;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 115.2px */
  text-transform: uppercase;
  margin-right: 2vw;
}
.wtf {
  display: flex;
}
.grLine {
  background: #6fb075;
  height: 3px;
  width: 6vw;
  margin-right: 2vw;
  margin-top: 2vw;
}
.par p {
  color: #3f4245;
  font-family: Montserrat;
  font-size: 1.6vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.par b {
  color: #ba9766;
}
.par div {
  display: flex;
  align-items: center;
  margin-bottom: 1.6vw;
}
.par img {
  width: 1.7vw;
  margin-right: 1vw;
}
.flex {
  display: flex;
}
.sssopr {
  background: #fbf2f2;
  padding: 3.8vw;
  margin-top: 4vw;
  padding-right: 7vw;
  width: fit-content;
  position: relative;
}
.sssopr h4 {
  color: #525252;
  font-family: Montserrat;
  font-size: 2vw;
  margin-bottom: 2.5vw;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 56px */
  text-transform: uppercase;
}
.sssopr li p {
  color: #3f4245;
  font-family: Montserrat;
  font-size: 1.6vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.sssopr li img {
  width: 1.6vw;
  margin-right: 0.5vw;
}
.sssopr li {
  padding: 0;
  border-bottom: 0;
  margin-bottom: 1.5vw;
}
.sssopr ul {
  margin-right: 2vw;
}
.cor,
.ex {
  position: absolute;
}
.cor {
  width: 23.3vw;
  left: 32vw;
  top: 1vw;
}
.ex {
  right: -12vw;
  top: 8vw;
  width: 19vw;
}
@media (max-width: 640px) {
  .mobS {
    width: 99vw;
    margin-left: -3vw;
    z-index: 5;
    position: relative;
  }
  .kolk {
    display: none;
  }
  .support h3 {
    color: #525252;
    font-family: Montserrat;
    font-size: 9vw;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    text-transform: uppercase;
  }
  .support > p {
    color: #525252;
    font-family: Montserrat;
    font-size: 4.6vw;
    font-style: normal;
    font-weight: 600;
    margin-top: 6vw;
    line-height: 120%;
    text-transform: uppercase;
    margin-bottom: 9vw;
  }
  .thf b {
    color: #fff;
    font-family: Montserrat;
    background: #ba9766;
    font-size: 9vw;
    padding: 4.5vw 2.5vw;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    text-transform: uppercase;
    margin-right: 4vw;
  }
  .wtf {
    flex-direction: column;
  }
  .par img {
    width: 5.7vw;
    margin-right: 3vw;
  }
  .par p {
    color: #3f4245;
    font-family: Montserrat;
    font-size: 4.2vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .par div {
    display: flex;
    align-items: center;
    margin-bottom: 6.6vw;
  }
  .flex {
    flex-direction: column;
  }
  .sssopr h4 {
    color: #525252;
    font-family: Montserrat;
    font-size: 7vw;
    width: 60vw;
    margin-bottom: 5vw;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    text-transform: uppercase;
  }
  .sssopr li {
    padding: 0;
    border-bottom: 0;
    margin-bottom: 4.5vw;
  }
  .ex {
    right: 5vw;
    top: 1vw;
    width: 20vw;
  }
  .sssopr li p {
    color: #3f4245;
    font-family: Montserrat;
    font-size: 4vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .sssopr li img {
    width: 4.6vw;
    margin-right: 2.5vw;
  }
  .sssopr {
    background: #fbf2f2;
    padding: 5.8vw;
    margin-top: -18vw;
    padding-right: 7vw;
    width: -moz-fit-content;
    width: 98%;
    padding-top: 10vw;
    position: relative;
  }
  .support {
    padding-bottom: 8vw;
  }
  .cor {
    display: none;
  }
  .grLine {
    background: #6fb075;
    height: 2px;
    width: 49vw;
    margin-right: 2vw;
    margin-top: 2vw;
    margin-bottom: 8vw;
  }
  .thf p {
    color: #525252;
    font-family: Montserrat;
    font-size: 3.5vw;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    text-transform: uppercase;
  }
}
