.Price {
  background: url(./img/fon.png);
  background-size: cover;
  padding: 6vw 0;
  padding-left: 6vw;
}
.train {
  display: flex;
  width: 93.3vw;
  overflow-x: scroll;
  padding-bottom: 3vw;
}

.rfm-marquee-container {
  overflow-x: scroll !important;
  padding-bottom: 2vw;
}
.Price h2 {
  color: #dbb176;
  font-family: Montserrat;
  font-size: 4.3vw;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 85px */
  text-transform: uppercase;
  margin-bottom: 1vw;
}
.train img {
  width: 37.5vw;
  margin-right: 2vw;
}
.Price h3 {
  font-size: 4.3vw;
  font-style: normal;
  color: #525252;
  font-weight: 700;
  line-height: 100%; /* 85px */
  text-transform: uppercase;
  margin-left: 12vw;
}
.Price > p {
  color: #525252;
  font-family: Montserrat;
  font-size: 1.8vw;
  font-style: normal;
  margin-top: 2vw;
  margin-bottom: 5vw;
  font-weight: 400;
  line-height: 100%; /* 36.278px */
  letter-spacing: 5.442px;
}
@media (max-width: 640px) {
  .Price {
    background-size: cover;
    padding: 6vw 3vw;
  }
  .train {
    width: 94vw;
  }
  .train img {
    width: 94vw;
    margin-right: 1vw;
  }
  .Price h3 {
    font-size: 9.3vw;
    font-style: normal;
    color: #525252;
    font-weight: 700;
    line-height: 100%;
    text-transform: uppercase;
    margin-left: 0;
  }
  .Price > p {
    color: #525252;
    font-family: Montserrat;
    font-size: 4.8vw;
    font-style: normal;
    margin-top: 2vw;
    margin-bottom: 5vw;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: 0.442px;
  }
  .Price h2 {
    color: #dbb176;
    font-family: Montserrat;
    font-size: 9.3vw;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    text-transform: uppercase;
    margin-bottom: 1vw;
  }
}
