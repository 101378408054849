.oneContent {
  display: flex;
  align-items: center;
  padding-top: 5vw;
  margin-bottom: 4vw;
}
.oneContent img {
  width: 44vw;
  margin-right: 4.6vw;
}
.oneContent h3 {
  color: #525252;
  font-family: Montserrat;
  font-size: 5vw;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  text-transform: uppercase;
}
.oneContent span {
  color: #ba9766;
}
.oneNext {
  position: relative;
  display: flex;
  height: 67vw;
  align-items: flex-start;
}
.oneNextRight {
  position: absolute;
  left: 21vw;
  width: 74vw;
  top: 0;
}
.oneNext > img {
  width: 40vw;
}
.oneNextRight img {
  width: 100%;
}
.oneContent p {
  color: #ba9766;
  font-family: Montserrat;
  margin-top: 2.5vw;
  font-size: 2vw;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 48px */
  text-transform: uppercase;
}
@media (max-width: 640px) {
  .oneContent {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
  .oneBy {
    padding: 3vw;
  }
  .oneContent h3 {
    color: #525252;
    font-family: Montserrat;
    font-size: 9vw;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    text-transform: uppercase;
  }
  .oneContent p {
    color: #ba9766;
    font-family: Montserrat;
    margin-top: 2.5vw;
    font-size: 4.5vw;
    font-style: normal;
    margin-bottom: 8vw;
    font-weight: 600;
    line-height: 120%;
    text-transform: uppercase;
  }
  .oneContent img {
    width: 100%;
    margin-right: 0;
  }
  .oneNext > img {
    display: none;
  }
  .oneNextRight {
    position: static;
    left: 21vw;
    width: 100%;
    top: 0;
    margin-top: 2vw;
  }
  .oneNext {
    position: relative;
    display: flex;
    height: 145vw;
    align-items: flex-start;
  }
}
