.fullInstructure {
  display: flex;
  align-items: center;
  margin-top: 4vw;
}
.Infr {
  padding: 3vw 2vw;
}
.fullInstructure h3 {
  text-align: right;
  color: #525252;
  margin-top: 1vw;
  margin-left: 4.5vw;
  text-align: right;
  font-family: Montserrat;
  font-size: 5vw;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 105.6px */
  text-transform: uppercase;
}
.blocc {
  width: fit-content;
  padding: 2vw 1.5vw;
  height: 15vw;
  display: flex;
  margin-right: 2vw;
  flex-direction: column;
  align-items: center;
  border: 2.751px solid rgba(157, 169, 153, 0.14);
}
.blocc img {
  height: 3vw;
  margin-bottom: 1vw;
}
.blocc p {
  color: #525252;
  text-align: center;
  font-family: Montserrat;
  font-size: 1.7vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0.5vw;
}
.blocc span {
  color: #525252;
  text-align: center;
  font-family: Montserrat;
  font-size: 1.1vw;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 33.009px */
}
.InfrContent {
  display: flex;
  justify-content: space-between;
  margin-top: 4vw;
}
.bl {
  display: flex;
}
.rightInfr {
  width: 37%;
}
.rightInfr img {
  width: 100%;
}
.rigi {
  position: relative;
  z-index: 2;
}
.palmD {
  margin-top: -16vw;
}
.smallic {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 2vw;
  margin-top: 5vw;
}
.smallic img {
  margin-left: 5vw;
  width: 22.5vw;
}
.pr {
  color: #fff;
  font-family: Montserrat;
  font-size: 1.1vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  border-radius: 6vw;
  padding: 2vw 3.5vw;
  width: fit-content;
  cursor: pointer;
  margin: 0 auto;
  margin-top: -4vw;
  background: #ba9766;
  position: relative;
  z-index: 3;
}
.pr:hover,
.button:hover {
  background: #816846;
}
@media (max-width: 640px) {
  .fullInstructure h3 {
    display: none;
  }

  .fullInstructure .blocc {
    width: 48%;
    margin-right: 0;
    height: 38vw;
  }
  .fullInstructure {
    justify-content: space-between;
  }
  .blocc p {
    color: #525252;
    text-align: center;
    font-family: Montserrat;
    font-size: 3.7vw;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 0.5vw;
  }
  .blocc span {
    color: #525252;
    text-align: center;
    font-family: Montserrat;
    font-size: 2.5vw;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
  .blocc img {
    height: 9vw;
    margin-bottom: 5vw;
    margin-top: 3vw;
  }
  .mobsik img {
    width: 100%;
  }
  .mobsik {
    width: 100%;
    margin-top: 7vw;
  }
  .mobsik div {
    margin-top: 4vw;
    display: flex;
    justify-content: space-between;
  }
  .mobsik div img {
    width: 47%;
  }
  .smallic {
    display: none;
  }
  .pr {
    margin-top: 8vw;
    font-size: 4vw;
    padding: 6vw;
    border-radius: 1000px;
    width: 90%;
    text-align: center;
  }

  .Infr {
    padding-bottom: 8vw;
  }
}
