.question {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 3vw;
  padding-bottom: 5vw;
}
.questEm .vibi:last-child {
  border-bottom: none;
}
.polo {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.question h3 {
  color: #525252;
  text-align: center;
  font-family: Montserrat;
  font-size: 5vw;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 105.6px */
  text-transform: uppercase;
  margin-bottom: 4.5vw;
}
.hiden {
  margin-top: 1.5vw;
  color: #525252 !important;
  font-family: Montserrat;
  font-size: 1.2vw;
  font-style: normal;
  height: 0;
  overflow: hidden;
  font-weight: 400;
  line-height: 150%; /* 36px */
  width: 90%;
  margin-left: 10%;
}
.vibi {
  width: 85vw;
  cursor: pointer;
  justify-content: space-between;
  padding: 2vw 0;
  border-bottom: 2px solid rgba(194, 194, 194, 0.4);
}
.vibi p {
  color: #525252;
  font-family: Montserrat;
  font-size: 1.3vw;
  margin-right: auto;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.vibi div {
  color: #ba9766;
  font-size: 1.3vw;
  margin-right: 2vw;
}
.vibi img {
  margin-left: 2vw;
  width: 3vw;
}
@media (max-width: 640px) {
  .vibi {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 94vw;
    cursor: pointer;
    justify-content: space-between;
    padding: 5vw 0;
    border-bottom: 2px solid rgba(194, 194, 194, 0.4);
  }
  .vibi div {
    color: #ba9766;
    font-size: 6.3vw;
    margin-right: 4vw;
  }
  .vibi img {
    margin-left: 5vw;
    width: 7vw;
    min-width: 7vw;
  }
  .hiden {
    font-size: 3.5vw !important;
  }
  .question h3 {
    color: #525252;
    text-align: center;
    font-family: Montserrat;
    font-size: 7vw;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    text-transform: uppercase;
    margin-bottom: 7.5vw;
  }
  .question {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 8vw;
    padding-bottom: 10vw;
  }
  .vibi p {
    color: #525252;
    font-family: Montserrat;
    font-size: 4.3vw;
    margin-right: auto;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}
