.Alania h3 {
  color: #525252;
  font-family: Montserrat;
  font-size: 5vw;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 115.2px */
  text-transform: uppercase;
}
.Alania {
  padding: 3vw 2vw;
}
.Alania > img {
  width: 100%;
  display: block;
  margin-top: 3vw;
}
.mnUl {
  display: flex;
  background: #fbf2f2;
  padding: 4vw;
}
.mnUl ul div {
  color: rgba(186, 151, 102, 1);
  font-size: 2vw;
  margin-right: 1vw;
}
.cetn {
  padding: 0 2vw;
  margin: 0 2vw;
  border-right: 1px solid rgba(186, 151, 102, 0.2);
  border-left: 1px solid rgba(186, 151, 102, 0.2);
}
.mnUl li {
  border-bottom: none;
  padding: 0;
  margin-bottom: 4vw;
}
.mnUl li p {
  color: #3f4245;
  font-family: Montserrat;
  font-size: 1.25vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.Alania .button {
  position: relative;
  color: #fff;
  font-family: Montserrat;
  font-size: 1.1vw;
  padding: 1.8vw 3.5vw;
  border-radius: 1000px;
  background: #ba9766;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: fit-content;
  top: -2.5vw;
  cursor: pointer;
  text-transform: uppercase;
}
@media (max-width: 640px) {
  .Alania h3 {
    color: #525252;
    font-family: Montserrat;
    font-size: 8.5vw;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    text-transform: uppercase;
  }
  .mnUl {
    display: flex;
    background: #fbf2f2;
    padding: 8vw 4vw;
  }
  .mnUl > div {
    width: 100%;
  }
  .mnUl ul div {
    color: rgba(186, 151, 102, 1);
    font-size: 7vw;
    margin-right: 3vw;
  }
  .carousel-dots li button:before {
    font-size: 75px !important;
  }
  .carousel-dots li.carousel-dots-active button:before {
    opacity: 1 !important;
    color: #dbb176 !important;
  }
  .cetn {
    border: none !important;
  }
  .carousel-item {
    transition: 0s;
  }
  .Alania {
    padding: 10vw 3vw;
  }
  .Alania .button {
    position: relative;
    color: #fff;
    font-family: Montserrat;
    font-size: 3.1vw;
    margin: 0 auto;
    padding: 5.8vw 12.5vw;
    border-radius: 1000px;
    background: #ba9766;
    font-style: normal;
    font-weight: 600;
    margin-top: 5vw;
    line-height: normal;
    width: -moz-fit-content;
    width: fit-content;
    top: 0;
    cursor: pointer;
    text-transform: uppercase;
  }
  .mnUl li {
    border-bottom: none;
    padding: 0;
    margin-bottom: 5vw;
  }
  .mnUl li p {
    color: #3f4245;
    font-family: Montserrat;
    font-size: 3.5vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .mnUl ul {
    min-width: 86vw;
    margin-right: 4vw;
  }
}
