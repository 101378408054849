.obzorContent {
  display: flex;
}
.Obzor {
  padding-top: 6vw;
  padding-left: 6vw;
  padding-bottom: 6vw;
  position: relative;
  overflow: hidden;
}
.oPalm {
  position: absolute;
  top: -10vw;
  right: 0;
  width: 15vw;
}
.bookPodl {
  position: absolute;
  z-index: 3;
  pointer-events: none;
  left: -6vw;
  top: -5vw;
  width: 70vw;
}
.kvi {
  display: none;
}
.imgPodl {
  width: 37vw;
  display: block;
}
.podl {
  background: #fff9f1;
  width: 88vw;
  margin-top: 6vw;
  display: flex;
  position: relative;
}
.rightPodl {
  padding: 4vw;
}
.vibor {
  margin-left: 10vw;
  color: #3f4245;
  font-family: Montserrat;
  font-size: 1.2vw;

  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 33.6px */
}
.vibor label {
  margin-left: 1vw;
}
.vibor div {
  margin-bottom: 1.3vw;
  display: flex;
  align-items: center;
}
.rightPodl > p {
  color: #fff;
  font-family: Montserrat;
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 500;
  padding: 0.7vw 1vw;
  width: fit-content;
  background: #6fb075;
  line-height: normal;
  margin-bottom: 2vw;
}
input[type="checkbox"] {
  background-color: #6fb075;
  width: 1.5vw;
  height: 1.5vw;
  min-width: 1.5vw;
  border-radius: 50%;
  overflow: hidden;
}
input[type="checkbox"]:checked {
  background-color: green;
}
@keyframes bord {
  0% {
    -webkit-box-shadow: 0px 0px 51px -41px rgba(186, 151, 102, 0.75);
    -moz-box-shadow: 0px 0px 51px -41px rgba(186, 151, 102, 0.75);
    box-shadow: 0px 0px 51px -41px rgba(186, 151, 102, 0.75);
  }
  50% {
    -webkit-box-shadow: 0px 0px 54px -5px rgba(186, 151, 102, 0.75);
    -moz-box-shadow: 0px 0px 54px -5px rgba(186, 151, 102, 0.75);
    box-shadow: 0px 0px 54px -5px rgba(186, 151, 102, 0.75);
  }
  100% {
    -webkit-box-shadow: 0px 0px 51px -41px rgba(186, 151, 102, 0.75);
    -moz-box-shadow: 0px 0px 51px -41px rgba(186, 151, 102, 0.75);
    box-shadow: 0px 0px 51px -41px rgba(186, 151, 102, 0.75);
  }
}
.podl h4 {
  color: #525252;
  font-family: Montserrat;
  font-size: 3vw;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 70.4px */
  text-transform: uppercase;
  margin-bottom: 2vw;
}
.quiz_buttons {
  display: flex;
  margin-top: 2vw;
  margin-left: 10vw;
}
.back {
  color: #825454;
  font-family: Montserrat;
  font-size: 1vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1.765px;
  text-transform: uppercase;
  background: #f3e4ce;
  border-radius: 6vw;
  padding: 1.5vw 3vw;
  margin-right: 2.5vw;
  cursor: pointer;
}
.next {
  color: #fff;
  cursor: pointer;
  font-family: Montserrat;
  font-size: 1vw;
  background: #ba9766;
  border-radius: 6vw;
  padding: 1.5vw 6vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}
.Obzor h2 {
  color: #525252;
  font-family: Montserrat;
  font-size: 5vw;
  font-style: normal;
  margin-bottom: 1vw;
  font-weight: 700;
  line-height: 110%; /* 105.6px */
  text-transform: uppercase;
}
.firstSp {
  padding-left: 3vw;
  border-left: 2px solid rgba(219, 177, 119, 0.5);
  margin-bottom: 3.5vw;
}
.rightPodl span {
  text-transform: uppercase;
  font-size: 1.8vw;
  margin-bottom: 2vw;
  display: block;
  color: #525252;
}
.firstSp h3 {
  color: #525252;
  font-family: Montserrat;
  font-size: 3.3vw;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 70.4px */
  text-transform: uppercase;
  margin-bottom: 0.5vw;
}
.firstSp p {
  color: #525252;
  font-family: Montserrat;
  font-size: 2vw;
  font-style: normal;
  font-weight: 500;
  line-height: 110%; /* 44px */
  text-transform: uppercase;
}
.firstSp ul {
  margin-top: 2.5vw;
}
.firstSp li img {
  width: 1.6vw;
  margin-right: 1vw;
}
.firstSp li p {
  color: #3f4245;
  font-family: Montserrat;
  font-size: 1.6vw;
  text-transform: none;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.ef > img {
  width: 100%;
}
.firstSp li {
  padding: 0;
  border-bottom: 0;
  margin-bottom: 1.5vw;
}
.Obzor .pr {
  margin-left: 0;
  margin-top: 0;
}
.expert {
  color: #fff;
  font-family: Montserrat;
  font-size: 2vw;
  background: #6fb075;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 1vw 1.3vw;
  margin-bottom: 2.5vw;
  width: 100%;
}
.obzorLeft {
  width: 35.5vw;
  margin-right: 4vw;
}
.obzorLeft .ef {
  width: 100%;
  position: relative;
}
.flexIn > div {
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
}
.vibor {
  height: 21vw;
}
.lastWord {
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  width: 27.5vw;
  margin-top: 1.5vw;
}
.lastWord input {
  padding: 1.5vw 2vw;
  font-size: 1vw;
  width: 100%;
  margin-top: 2.5vw;
  background: #f2eeec;
  font-weight: 600;
  border-radius: 1000px;
  border: none;
  outline: none;
}
.sdq {
  color: #fff;
  background: #ba9766;
  border-radius: 1000px;
  padding: 1.8vw 2vw;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  font-size: 1vw;
  margin-top: 2.5vw;
  justify-content: center;
  cursor: pointer;
}
.vibor > p {
  color: #525252;
  font-family: Montserrat;
  font-size: 1vw;
  font-style: normal;
  font-weight: 500;
  line-height: 110%; /* 22px */
  margin-bottom: 1.5vw;
}
.vibor h4 {
  margin-bottom: 0vw;
  color: #525252;
  font-family: Montserrat;
  font-size: 1.6vw;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 38.4px */
  text-transform: uppercase;
}
.Obzor iframe {
  width: 100%;
  height: 63.2vw;
}
.play {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 15vw !important;
  transform: translate(-50%, -50%);
  cursor: pointer;
}
.play:hover {
  filter: brightness(1.1);
}
.obzorRight {
  margin-top: 0.5vw;
}
@media (max-width: 640px) {
  .Obzor {
    padding: 12vw 3vw !important;
  }
  .podl {
    flex-direction: column;
    width: 100%;
  }
  .vibor {
    margin-left: 0;
  }
  .rightPodl span {
    text-transform: uppercase;
    font-size: 5.8vw;
    margin-bottom: 4vw;
    display: block;
    color: #525252;
  }
  .lastWord {
    width: 100%;
  }
  .lastWord input {
    font-size: 3vw;
    padding: 6vw;
    margin-top: 7vw;
  }
  .sdq {
    font-size: 3vw;
    padding: 6vw;
    margin-top: 7vw;
  }
  .vibor h4 {
    margin-bottom: 0vw;
    color: #525252;
    font-family: Montserrat;
    font-size: 4.6vw !important;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    text-transform: uppercase;
    margin-bottom: 0 !important;
  }
  .flexIn > div {
    align-items: flex-start !important;
  }
  .vibor label {
    margin-left: 3vw;
    font-size: 3vw;
    line-height: normal;
  }
  .vibor div {
    margin-bottom: 5.3vw;
    display: flex;
    align-items: center;
  }
  input[type="checkbox"] {
    background-color: #6fb075;
    width: 6.5vw;
    height: 6.5vw;
    border-radius: 50%;
    overflow: hidden;
  }
  .vibor {
    height: auto;
  }
  .vibor > p {
    color: #525252;
    font-family: Montserrat;
    font-size: 4vw;
    font-style: normal;
    font-weight: 500;
    line-height: 110%;
    margin-bottom: 5.5vw;
  }
  .quiz_buttons {
    margin-left: 0;
    justify-content: space-between;
    margin-top: 8vw;
  }
  .vibor {
    height: 78vw;
  }
  .rightPodl {
    padding-top: 0;
    padding-bottom: 8vw;
  }
  .next {
    color: #fff;
    cursor: pointer;
    font-family: Montserrat;
    font-size: 4vw;
    background: #ba9766;
    border-radius: 13vw;
    padding: 4.5vw 15vw;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
  }
  .back {
    color: #825454;
    font-family: Montserrat;
    font-size: 4vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.765px;
    text-transform: uppercase;
    background: #f3e4ce;
    border-radius: 9vw;
    padding: 4.5vw 8vw;
    margin-right: 2.5vw;
    cursor: pointer;
  }
  .rightPodl > p {
    color: #fff;
    font-family: Montserrat;
    font-size: 4.5vw;
    font-style: normal;
    font-weight: 500;
    padding: 1.7vw 3vw;
    width: -moz-fit-content;
    width: fit-content;
    background: #6fb075;
    line-height: normal;
    margin-bottom: 5vw;
  }
  .podl h4 {
    color: #525252;
    font-family: Montserrat;
    font-size: 8vw;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    text-transform: uppercase;
    margin-bottom: 2vw;
  }
  .obzorContent {
    flex-direction: column;
  }
  .imgPodl {
    display: none;
  }
  .bookPodl {
    display: none;
  }
  .kvi {
    display: block;
    width: 100vw;
    margin-left: -3vw;
  }
  .Obzor h2 {
    color: #525252;
    font-family: Montserrat;
    font-size: 9vw;
    font-style: normal;
    margin-bottom: 3vw;
    font-weight: 700;
    line-height: 110%;
    text-transform: uppercase;
  }
  .obzorLeft {
    width: 100%;
    margin-right: 0;
  }
  .expert {
    color: #fff;
    font-family: Montserrat;
    font-size: 4vw;
    background: #6fb075;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 2vw 3.3vw;
    margin-bottom: 6vw;
    width: 77%;
  }
  .Obzor iframe {
    width: 100%;
    height: 167.2vw;
  }
  .play {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 37vw !important;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }
  .obzorRight {
    margin-top: 6.5vw;
  }
  .firstSp h3 {
    color: #525252;
    font-family: Montserrat;
    font-size: 7.3vw;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    text-transform: uppercase;
    margin-bottom: 1vw;
  }
  .firstSp p {
    color: #525252;
    font-family: Montserrat;
    font-size: 4vw;
    font-style: normal;
    font-weight: 500;
    line-height: 110%;
    text-transform: uppercase;
  }
  .ef > img {
    width: 100%;
  }
  .firstSp li {
    padding: 0;
    border-bottom: 0;
    margin-bottom: 3.65vw;
  }
  .firstSp li img {
    width: 4.6vw;
    margin-right: 3vw;
  }
  .firstSp {
    padding-left: 3vw;
    border-left: 2px solid rgba(219, 177, 119, 0.5);
    margin-bottom: 9vw;
  }
  .firstSp li p {
    color: #3f4245;
    font-family: Montserrat;
    font-size: 3.8vw;
    text-transform: none;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .Obzor .pr {
    width: 100%;
    font-size: 4.5vw;
    padding: 6vw 10vw;
    line-height: 150%;
  }
}
